/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages

				/** GLOBAL VARIABLES */
				var body = document.querySelector('body');
				/** END GLOBAL VARIABLES */


				/** SKIP LINK NAVIGATION */
				$('#skip-to-content').click(function (event) {
					// strip the leading hash and declare
					// the content we're skipping to
					var skipTo = "#" + this.href.split('#')[1];
					// Setting 'tabindex' to -1 takes an element out of normal 
					// tab flow but allows it to be focused via javascript
					$(skipTo).attr('tabindex', -1).on('blur focusout', function () {
						// when focus leaves this element, 
						// remove the tabindex attribute
						$(this).removeAttr('tabindex');
					}).focus(); // focus on the content container
				});
				/** END SKIP LINK NAVIGATION */


				/* HEADER SCROLL EFFECT */
				// Reference: http://tympanus.net/codrops/2013/06/06/on-scroll-animated-header/
				var animatedHeader = (function () {
					var didScroll = false,
						scrollOffset = 0;
					function scrollY() {
						return window.pageYOffset || document.documentElement.scrollTop;
					}
					function scrollPage() {
						var sy = scrollY();
						if (sy > scrollOffset) {
							body.classList.add('scroll-triggered');
						}
						else {
							body.classList.remove('scroll-triggered');
						}
						didScroll = false;
					}
					function init() {
						window.addEventListener('load', scrollPage);
						window.addEventListener('scroll', function (event) {
							if (!didScroll) {
								didScroll = true;
								setTimeout(scrollPage, 250);
							}
						}, false);
					}
					init();
				})();
				/* END HEADER SCROLL EFFECT */

				/** RESPONSIVE NAV OPEN/CLOSE **/
				var mobileNav = document.querySelector('#mobile-nav');
				var mobileNavButton = document.querySelector('#mobile-nav-icon');

				// Toggle menu icon
				if (mobileNavButton) {
					mobileNavButton.addEventListener('click', function () {
						body.classList.toggle('mobile-nav-open');
					});
				}

				// Close menu if page is clicked (not menu item)
				if (mobileNav) {
					mobileNav.addEventListener('click', function (e) {
						// Don't hide if click is on a child element (nav link)
						if (e.target !== this) {
							return;
						}
						mobileNavButton.classList.toggle('opened');
						$("#nav-toggle").prop('checked', false);
					});
				}
				/** END RESPONSIVE NAV OPEN/CLOSE **/


				/** SHOW AND HIDE SEARCH BAR **/
				var searchButton = $('#search-button'),
					headerMain = $('#header-main'),
					headerSearch = $('#header-search'),
					searchClose = $('#search-close');

				searchButton.click(function () {
					headerMain.fadeToggle('fast', function () {
						headerSearch.fadeToggle('slow');
						// Focus on input for all browsers except IE11. They have a placeholder text bug.
						if ((window.ActiveXObject) && !("ActiveXObject" in window)) {
							headerSearch.find('input[type="text"]').focus();
						}
					});
					return false;
				});
				searchClose.click(function () {
					headerSearch.fadeToggle('fast', function () {
						headerMain.fadeToggle('slow');
					});
				});
				// Shake Animation
				function shake(div) {
					var interval = 100;
					var distance = 10;
					var times = 4;
					$(div).css('position', 'relative');
					for (var iter = 0; iter < (times + 1); iter++) {
						$(div).animate({ left: ((iter % 2 === 0 ? distance : distance * -1)) }, interval);
					}
					$(div).animate({ left: 0 }, interval);
				}
				// Search Submit
				headerSearch.submit(function (e) {
					var s = $(this).find('input[type="text"]');
					if (!s.val()) {
						e.preventDefault();
						shake($(this).find('input[type="text"]'));
						$(this).find('input[type="text"]').focus();
					}
				});
				/** END SHOW OR HIDE SEARCH BAR **/


				/** MASTHEAD PRGRESSIVE IMAGES **/
				var masthead = document.querySelector('.masthead'),
					placeholderOverlay = document.querySelector('.placeholder-overlay');

				if (masthead && placeholderOverlay) {
					// Load full size image. When loaded, fade our placeholder add it as bg to masthead
					var img = new Image();
					img.src = masthead.dataset.imageSrc;
					img.onload = function () {
						placeholderOverlay.classList.add('fade-out');
						masthead.style.backgroundImage = "url(" + img.src + ")";
					};
				}
				/** END MASTHEAD PRGRESSIVE IMAGES **/


				/** ANIMATED ANCHOR LINKS **/
				$('a[href*="#"]:not([href="#"])').click(function (e) {
					if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
						var target            = $(this.hash);
						var $this             = this;
						var header            = $('header.banner');
						var wpAdminBar        = $('#wpadminbar');
						var fixedHeaderOffset = parseInt(header.outerHeight());
						target                = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

						if(wpAdminBar.length) {
							fixedHeaderOffset = parseInt(header.outerHeight() + wpAdminBar.outerHeight());
						}

						if (target.length) {

							console.log(target.offset().top);

							$('html,body').animate({
								scrollTop: (target.offset().top)
							}, 500, function () {
								if (history.pushState) {
									history.pushState(null, null, $this.hash);
								}
								else {
									location.hash = $this.hash;
								}
							});
							e.preventDefault();
						}
					}
				});
				/** END ANIMATED ANCHOR LINKS **/


				/* NUMBER TICKER UPON VIEW */
				// IMPORTANT: Requires Waypoint.js and Counterup.js (Enqueued from JSDelivr)
				var numberTickers = $('.ticker');
				if (numberTickers.length) {
					numberTickers.counterUp({
						time: 700
					});
				}
				/* END NUMBER TICKER UPON VIEW */

				// Masonry layout
				var $container = $(".posts-list .row");
				$container.imagesLoaded( function() {
					$container.masonry({
						itemSelector: '.column',
						columnWidth: '.md-50'
					});
				});

				$(".sub-nav a").each(function(){
					var a = $(this);
					a.click(function(){
						$(".sub-nav a").parent().removeClass("active"); // remove current active
						a.parent().addClass("active");
					}); 
				});

				// Instagram feed
				var feed = $('.wdi_feed_container');
				if (feed.length) {
					feed.append($('#wdi_pagination'));
				}

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
